<template>
  <div class="app-container">
    <div class="flex-between">
      <div>
        <el-button size="small" type="primary" @click="$router.back()"
          >返回</el-button
        >
      </div>
      <PaginationBar :page="page" :size="size" :total="total" @refreshTableEventFun="refreshTableEventFun" />
    </div>
    <CommonTable style="margin-top: 20px" :selection="false" :tableLoading="tableLoading" :cols="cols" :infoData="data">
      <template #tradeTimeSlot="{ scoped: row }"> {{ parseTime(row[row.prop]) }}</template>
      <template #actionSlot="{ scoped: row }">{{ type(row.tradeType) }}</template>
      <el-table-column fixed="right" label="操作" width="350px" align="center">
        <template slot-scope="{ row }">
          <color-text-btn @click="linkTo(row)">详情</color-text-btn>
        </template>
      </el-table-column>
    </CommonTable>
    <PaginationBar ref="paginationBar" @refreshTableEventFun="refreshTableEventFun" :total="total" />
  </div>
</template>

<script>
import { historyCols as cols } from './cols'
import initDataMixin from '@/mixins/initData'
import { parseTime } from '@/utils'
import { TRADE_TYPE_LABELS } from '@/utils/constant/paymentConst'

export default {
  mixins: [initDataMixin],
  data() {
    return {
      cols,
      url: '/externaladmin/financeService/paymentAccount/balanceChangeRecordList',
      mergeData: {
        id: this.$route.query.id,
        orderItems: [
          {
            column: 'trade_time',
            asc: false
          }
        ]
      }
    }
  },
  methods: {
    parseTime,
    linkTo(data) {
      const { tradeType, id, isIncome } = data
      this.$router.push({ path: '/user/destroy/history/detail', query: { id, tradeType, isIncome } })
    }
  },
  computed: {
    type() {
      return (type) => {
        return TRADE_TYPE_LABELS[type]
      }
    }
  },
  mounted() {
    // const { id } = (this.mergeData.id = Number(id))
  }
}
</script>

<style lang="scss" scoped>
.app-container {
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
}
</style>
